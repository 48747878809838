import axios from "axios";

const token = () => {
  if (localStorage.getItem("user") ) {
    return {
      "Accept": "Application/json",
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).api_token}`,
    };
  }
  return {
    "Accept": "application/json",
  };
};

const axiosApp = axios.create({
  baseURL: "https://ajrha.content-dev.com/api/v1",
  headers: { "Access-Control-Allow-Origin": "*", ...token() },
});

export default axiosApp;
