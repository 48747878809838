import {
  CHANGEPASSWORD_ERROR,
  CHANGEPASSWORD_REQUEST,
  CHANGEPASSWORD_SUCCESS,
  FORGETPASSWORD_ERROR,
  FORGETPASSWORD_REQUEST,
  FORGETPASSWORD_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_VERIFY,
  PAYMENT_SUCCESS,
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  VERIFYPASSWORD_ERROR,
  VERIFYPASSWORD_REQUEST,
  VERIFYPASSWORD_SUCCESS,
  VERIFY_ERROR,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "../../actions/auth/types";


const StateUser = {
  user: null,
  loading: false,
  error: "",
  mobile:null
};


const StateRegister = {
  loading: false,
  error: "",
};
// 

const StateVerify = {
  loading: false,
  error: "",
};
// 

const StateRestPass = {
  loading: false,
  error: "",
};
// 

const Stateverify_password = {
  loading: false,
  error: "",
};
// 
const StateChangePassword = {
  loading: false,
  error: "",
};
// 


const StatePayment = {
  loading: false,
  error: "",
  checkout_url:null
};
// 


export const logedIn = function (state = StateUser, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOGIN_VERIFY:
      return {
        ...state,
        loading: false,
        mobile: action.payload,
      };
    default:
      return state;
  }
}
// 


export const restPass = function (state = StateRestPass, action) {
  switch (action.type) {
    case FORGETPASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGETPASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case FORGETPASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 


export const regsiteration = function (state = StateRegister, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        // api_token:action.payload
      }; 
    case REGISTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 


export const verify = function (state = StateVerify, action) {
  switch (action.type) {
    case VERIFY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case VERIFY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 


export const verifyPassword = function (state = Stateverify_password, action) {
  switch (action.type) {
    case VERIFYPASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case  VERIFYPASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case VERIFYPASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 




export const changePassword = function (state = StateChangePassword, action) {
  switch (action.type) {
    case CHANGEPASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case  CHANGEPASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case CHANGEPASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 



export const payment = function (state = StatePayment, action) {
  switch (action.type) {
    
    case  PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkout_url : action.payload,
        callback_url : action.payload,
      }; 
    
    default:
      return state;
  }
}

// 

