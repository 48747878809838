import { ORDER_ERROR, ORDER_REQUEST, ORDER_SUCCESS } from "../../actions/order/types";

const StateOrder = {
  dataOrder: null,
  loading: false,
  error: "",
};
// 


export const order = function (state = StateOrder, action) {
  switch (action.type) {
    case ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        dataOrder:action.payload
      }; 
    case ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 