import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const PageNotFound = () => {

  return (
    <>
      <Helmet>
        <title> أجرها | الصفحة غير موجودة  </title>
      </Helmet>
      <header className="main-header">
        <div className="text-center">
          <img src={logo} alt="logo" />
        </div>
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-1 text-center my-4">عذراً ، حدث خطأ مـا</h3>
        <h5 className="text-gray mb-4 text-center">
        الصفحة التي تحاول البحث عنها غير موجودة يرجى المحاولة مرة آخرى او العودة الى <Link to="/">الرئيسية</Link>
        </h5>
      </div>
    </>
  );
};

export default PageNotFound;
