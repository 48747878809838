import {
  CHANGEPASSWORD_ERROR,
  CHANGEPASSWORD_REQUEST,
  CHANGEPASSWORD_SUCCESS,
  FORGETPASSWORD_ERROR,
  FORGETPASSWORD_REQUEST,
  FORGETPASSWORD_SUCCESS,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_VERIFY,
  PAYMENT_SUCCESS,
  REGISTER_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  VERIFYPASSWORD_ERROR,
  VERIFYPASSWORD_REQUEST,
  VERIFYPASSWORD_SUCCESS,
  VERIFY_ERROR,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "./types";
import axiosApp from "../../../api/index.js";
import Cookies from 'js-cookie'


export const login =
  ({ values, callback }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
      });
      const response = await axiosApp.post(`/auth/login`, values);
      if(response.data.data.account.is_verified_mobile === false){
        localStorage.setItem("user", JSON.stringify(response.data.data));
        dispatch({
          type: LOGIN_VERIFY,
          payload: response.data.data.account.mobile,
        });
      }else{
      dispatch({
        type: LOGIN_SUCCESS,
        // payload: response.data.data.account,
      });
      // localStorage.setItem("api_token", response.data.data.api_token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
      Cookies.set('api_token', response.data.data.api_token)
      // window.location.reload()
      setTimeout(
        function() {
          callback();
          console.log('sadasdasd')
        }
        .bind(this),
        500,
        setTimeout(
          function() {
            window.location.reload();
            console.log('sadasdasd')
          }
          .bind(this),
          1000
      )
    );
      }


    
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data.message,
      });
    }
  };

export const regsiter =
  ({ values, callback }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      });
      let response = await axiosApp.post(`/auth/register`, values);
      dispatch({
        type: REGISTER_SUCCESS,
      });
      localStorage.setItem("user", JSON.stringify(response.data.data));
      // localStorage.setItem("user", JSON.stringify(response.data.data.account));
      callback();
    } catch (error) {
      dispatch({
        type: REGISTER_ERROR,
        payload: error.response.data.errors,
      });
    }
  };

export const verify =
  ({ values, callback }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: VERIFY_REQUEST,
      });
      const response =  await axiosApp.post(`/auth/verify-two-factor`, values, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("user")).api_token
          }`,
        },
      });

      if(response.data.status === false){
        dispatch({
          type: VERIFY_ERROR,
          payload: response.data.message,
        });
      }else{
        dispatch({
          type: VERIFY_SUCCESS,
          // payload: value,
        });

        
        let user = JSON.parse(localStorage.getItem("user"));
        user = {
          ...user,
          account: {
            ...user?.account,
            is_verified_mobile: true,
          },
        };
        localStorage.setItem("user", JSON.stringify(user));
        Cookies.set('api_token', user.api_token)
        console.log('asdasd11111')
        
        setTimeout(
          function() {
            callback();
            console.log('sadasdasd222')
          }
          .bind(this),
            500,
            setTimeout(
              function() {
                window.location.reload();
                console.log('sadasdasd33')
              }
              .bind(this),
              1000
          )
        );
     

      }
      
    } catch (error) {
      dispatch({
        type: VERIFY_ERROR,
        payload: error.response.data.message,
      });
    }
  };




  
export const forget_password =
({ values, callback }) =>
async (dispatch) => {
  try {
    dispatch({
      type: FORGETPASSWORD_REQUEST,
    });
    await axiosApp.post(`/auth/reset-password`, values);
    dispatch({
      type: FORGETPASSWORD_SUCCESS,
    });
    callback();
  } catch (error) {
    dispatch({
      type: FORGETPASSWORD_ERROR,
      payload: error.response.data.message,
    });
  }
};


  
export const verify_password =
({ values, callback }) =>
async (dispatch) => {
  try {
    dispatch({
      type: VERIFYPASSWORD_REQUEST,
    });
   const response =  await axiosApp.post(`/auth/verify-verification-password`, values);
    if(response.data.status === false){
      dispatch({
        type: VERIFYPASSWORD_ERROR,
        payload: response.data.message,
      });
    }else{
      dispatch({
        type: VERIFYPASSWORD_SUCCESS,
      });
      callback();
    }
  } catch (error) {
    dispatch({
      type: VERIFYPASSWORD_ERROR,
      payload: error.response.data.errors,
    });
  }
};
 


export const change_password =
({ values, callback }) =>
async (dispatch) => {
  try {
    dispatch({
      type:  CHANGEPASSWORD_REQUEST,
    });
    await axiosApp.post(`/auth/change-password`, values);
    dispatch({
      type: CHANGEPASSWORD_SUCCESS,
    });
    callback();
  } catch (error) {
    dispatch({
      type: CHANGEPASSWORD_ERROR,
      payload: error.response.data.message,
    });
  }
};





export const payment =
  ({ values }) =>
  async (dispatch) => {
    // console.log(values)
    try {
      let response = await axiosApp.post(
        "orders/payment/checkout", values  );
      // console.log(response.data.data)

      dispatch({
        type: PAYMENT_SUCCESS,
        payload: response.data.data,
      });

    } catch (error) {
     
    } finally {
      
    }
  };