import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Form, Input, notification, Row, Select } from "antd";
import { ticketStore } from "../../../store/actions/tickets";
import { getTicketTypes } from "../../../store/actions/constants";
import { useNavigate } from "react-router-dom";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const { Option } = Select;

const TicketsِAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [picture, setPicture] = useState();
  const [imgData, setImgData] = useState(null);

  const { dataTicketTypes, loading, error } = useSelector((state) => state.ticketTypes);

  const { loading:LoadingTicketStore, error:ErrorTicketStore } = useSelector((state) => state.ticketStore);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getTicketTypes());
  }, [dispatch]);

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'تم إرسال التذكرة بنجاح',
      duration: 2
    })
  }


  const onSubnmit = (values) => {
    values = {
      ...values,
      file : picture
    }
    dispatch(
      ticketStore({
        values, 
        callback: () => {
          openNotificationWithIcon("success");
          form.resetFields();
          navigate("/tickets");
        },
      })
    );
  };
  const onChangePicture = e => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <Helmet>
        <title>أجرها - اضف تذكرة</title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">تذكرة جديدة</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
      <Form size="large" onFinish={onSubnmit} autoComplete="off" form={form} labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}>
          <Form.Item
            label="العنوان"
            name="title"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب!",
              },
            ]}
          >
            <Input placeholder="العنوان" />
          </Form.Item>
          <Form.Item
            label="نوع التذكرة"
            name="type_id"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب!",
              },
            ]}
          >

          {error ? error : (
            <Select loading={loading}
                placeholder="نوع التذكرة"
                allowClear
              >
                {dataTicketTypes?.data?.map((item, index) => (
                  <Option key={index} value={item.id}>{item.name}</Option>
                ))}
            </Select>
            )}
          </Form.Item>
            
          <Form.Item
            label="وصف التذكرة"
            name="content"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب!",
              },
            ]}
          >
            <Input.TextArea rows={5} placeholder="أدخل وصف التذكرة هنا" />
          </Form.Item>


          <label htmlFor="profilePic" className="uploadImage">
            <input id="profilePic" name="license_file" accept="/image/*" type="file" onChange={(e) => {
                  onChangePicture(e);
                }} />
            <span className="form-label">المرفقات </span>
          </label>
          <div className="py-2">
            <img className="imgData" src={imgData} />
          </div>

          <Form.Item>
            <Button loading={LoadingTicketStore} type="yellow" block htmlType="submit">
              ارسال
            </Button>
          </Form.Item>
        </Form>
        {ErrorTicketStore ?  <Alert type="error"  message={ErrorTicketStore} /> : null}
        
      </div>
    </>
  );
};

export default TicketsِAdd;
