import React from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Form, Input } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { change_password } from "../../store/actions/auth";
import chevron from "../../assets/images/svg/chevron-right.svg";

const ChangePassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation();

  const { error, loading } = useSelector((state) => state.changePassword);
  
  

  const onFinish = (values) => {
    values = {
      ...values,
      mobile:state?.mobile,
      code:state?.code
     };
     dispatch(
      change_password({
         values, 
         callback: () => {
          navigate(`/login`);
         },
       })
     );

  };
  console.log(state)

  if (!state?.mobile || !state?.code) {
    return <Navigate to="/forget-password" />;
  }
  return (
    <>
      <Helmet>
        <title> أجرها | استعادة كلمة المرور </title>
      </Helmet>
      <header className="main-header">
        <div className="p-1 pointer" onClick={() => navigate(-1)}>
          <img src={chevron} className="icon" alt="logo" />
        </div>
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-1">تأكيد</h3>
        <h5 className="text-gray mb-4">تم إرسال المصادقة إلى رقم {state?.mobile}</h5>
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          onFinish={onFinish}
        >
          <Form.Item
            label="كلمة المرور الجديدة"
            name="password"
            rules={[
              {
                required: true,
                message: "كلمة المرور الجديدة",
              },
            ]}
          >
            <Input placeholder="رمز التحقق" />
          </Form.Item>
          <Form.Item
            label="تأكيد كلمة المرور"
            name="confirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('كلمات المرور غير متطابقة'));
                },
              }),
            ]}
          >
            <Input placeholder="تأكيد كلمة المرور" />
          </Form.Item>
                  
          <Form.Item>
            <Button loading={loading} type="black" block htmlType="submit">
              تأكيد
            </Button>
          </Form.Item>
          {error ? <Alert type="error" message={error} /> : null}
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
