import { Col, Row } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import {  useLocation, useNavigate } from "react-router-dom";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const PaymentDetails = () => {

  const { state } = useLocation();
  const navigate = useNavigate();


  // useEffect(() => {
  //   console.log(state)
  //   // console.log( (( (state?.accessories.reduce((n, {cost}) => n + cost, 0) + state.price)*15/100 ) + state.price + (state?.accessories.reduce((n, {cost}) => n + cost, 0) ) )* state.differenceInDays  )
  //   // console.log( ( (state?.accessories.reduce((n, {cost}) => n + cost, 0) + state.price)*15/100 )  )
  //   // console.log(  state.price  )
  //   // console.log( (state?.accessories.reduce((n, {cost}) => n + cost, 0) ) )
  //   // console.log( state.differenceInDays )
  // }, [])

  const payment = ()=> {
    var values =  {};
    values.id = state?.id;
    values.price = state?.price;
    values.from = state?.from;
    values.to = state?.to;
    values.need_delivery = state?.need_delivery;
    values.accessories = state?.accessories.map(({ id }) => id);
    values.lat = state?.lat;
    values.lng = state?.lng;
    values.differenceInDays = state?.differenceInDays;
    var stringify = JSON.stringify(values);
    document.cookie = `booking=${stringify}`; 

  }


    
  if(state === null){
    navigate('/')
  }


  return (
    <>
      <Helmet>
        <title>  أجرها | تفاصيل الدفع  </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">تفاصيل الدفع</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        <div className="rounded bg-white border pt-3 mb-5">
          <div className="d-flex align-items-center justify-content-between px-3 py-1 mb-2">
            <h6 className="font-12">سعر الإيجار/اليوم</h6>
            <h6 className="font-12 font-medium">{state?.price} ريال</h6>
          </div>
            {state?.accessories?.map((item, index) => (
            <div className="d-flex align-items-center justify-content-between px-3 py-1 mb-2" key={index}>
              <h6 className="font-12">"خدمة إضافية " {item.name}</h6>
              <h6 className="font-12 font-medium">{item.cost} ريال</h6>
            </div>
            ))}
            {state?.discount ? (
              <div className="d-flex align-items-center justify-content-between px-3 py-1 mb-2">
              <h6 className="font-12 text-danger">الخصم</h6>
              <h6 className="font-12 font-medium text-danger">{state?.discount} </h6>
            </div>
            ) : null }
          <div className="d-flex align-items-center justify-content-between px-3 py-1 mb-2">
            <h6 className="font-12">ضريبة القيمة المضافة</h6>
            <h6 className="font-12 font-medium">{(state?.accessories.reduce((n, {cost}) => n + cost, 0) + state?.price)*15/100}   ريال</h6>
          </div>
          <div className="d-flex align-items-center justify-content-between px-3 py-1 mb-2">
            <h6 className="font-12">مدة الإيجار</h6>
            <h6 className="font-12 font-medium">{state?.differenceInDays} أيام</h6>
          </div>
          <hr className="mb-2" />
          <div className="d-flex align-items-center justify-content-between px-3 py-1 mb-3">
            <h6 className="font-medium">المبلغ الإجمالي</h6>
            <h6 className="font-medium">{ (( (state?.accessories.reduce((n, {cost}) => n + cost, 0) + state?.price)*15/100 ) + state?.price + (state?.accessories.reduce((n, {cost}) => n + cost, 0) ) )* state?.differenceInDays} ريال</h6>
          </div>
        </div>
        <a onClick={payment}  href="/payment/index" className="ant-btn-yellow" style={{width:"100%"}} >تأكيد الدفع</a>      
      </div>
    </>
  );
};

export default PaymentDetails;
