import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Input, Select } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { regsiter } from "../../store/actions/auth";
import { getCountries } from "../../store/actions/constants";
import logo from '../../assets/images/logo.svg'
const { Option } = Select;

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataCountries, loading:loadingCountries } = useSelector((state) => state.countries);
  const { error, loading } = useSelector((state) => state.regsiteration);

 

  const onFinssish = (values) => {
    dispatch(
      regsiter({
        values,
        callback: () => {
          console.log('afafqq')
          navigate("/register/verify", { replace: true })
          console.log('afafqqwwww')
        }
      })
    );
  };


  
  useEffect(() => {
    dispatch(
      getCountries()
    )
  }, []);

  // if (JSON.parse(localStorage.getItem("user"))?.account.is_verified_mobile === true) {
  //   return <Navigate to="/" />;
  // }

  return (
    <>
      <Helmet>
        <title> أجرها |  التسجيل  </title>
      </Helmet>
      <header className="main-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-3">إنشاء حساب</h3>
        {error ? (
          <ul className="alert-error mb-2">{error?.map((error, index) => (
              <li> - {error}</li>
          ))}</ul>
        ) : null}
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinssish}
        >
          <Form.Item
            label="الاسم"
            name="name"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="الاسم" />
          </Form.Item>

          <Form.Item
            label="الدولة"
            name="code"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Select allowClear loading={loadingCountries} placeholder="الدولة">
              {dataCountries?.data?.map((item, index) => (
                <Option key={index} value={item.code}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="رقم الجوال"
            name="mobile"
            rules={[
              {
                pattern: new RegExp(/^[0-9]+$/),
                message: "الرجاء ادخال رقم جوال صحيح",
              },
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="رقم الجوال" />
          </Form.Item>

          <Form.Item
            label="كلمة المرور"
            name="password"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
              { min: 8, message: 'يجب ان تكون كلمة المرور على الاقل 8 احرف' },
            ]}
          >
            <Input.Password placeholder="كلمة المرور" />
          </Form.Item>
        
          <Form.Item>
            <Button loading={loading} type="black" block htmlType="submit">
            تسجيل
            </Button>
          </Form.Item>
          <div className="text-center">
           لديك حساب ؟  <Link to="/login" className="text-dark"> سجل دخولك </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Register;
