

import { REPLAY_ERROR, REPLAY_REQUEST, REPLAY_SUCCESS, TICKETSDETAILS_ERROR, TICKETSDETAILS_REQUEST, TICKETSDETAILS_SUCCESS, TICKETSTORE_ERROR, TICKETSTORE_REQUEST, TICKETSTORE_SUCCESS, TICKETS_ERROR, TICKETS_REQUEST, TICKETS_SUCCESS } from "./types.js";
import axiosApp from "../../../api/index.js";

export const getTickets = () => async (dispatch) => {
  try {
    dispatch({
      type: TICKETS_REQUEST,
    });
    const response = await axiosApp.get(`/account/tickets/list`);
    dispatch({
      type: TICKETS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TICKETS_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const getTicketsDetails = ({id , callback}) => async (dispatch) => {
  try {
    dispatch({
      type: TICKETSDETAILS_REQUEST,
    });
    const response = await axiosApp.get(`/account/tickets/${id}/show`);
    dispatch({
      type: TICKETSDETAILS_SUCCESS,
      payload: response.data.data,
    });
    callback()
  } catch (error) {
    dispatch({
      type: TICKETSDETAILS_ERROR,
      payload: error.response.data.message,
    });
  }
};



export const replay = ({values , callback}) => async (dispatch) => {
  try {
    dispatch({
      type: REPLAY_REQUEST,
    });
    const response = await axiosApp.post(`/account/tickets/${values.id}/reply` , values);
    dispatch({
      type: REPLAY_SUCCESS,
      payload: response.data.data,
    });
    callback()
  } catch (error) {
    dispatch({
      type: REPLAY_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const ticketStore = ({values , callback}) => async (dispatch) => {
  try {
    dispatch({
      type: TICKETSTORE_REQUEST,
    });
    const response = await axiosApp.post(`/account/tickets/store` , values);
    dispatch({
      type: TICKETSTORE_SUCCESS,
      payload: response.data,
    });
    callback();
  } catch (error) {
    dispatch({
      type: TICKETSTORE_ERROR,
      payload: error.response.data.message,
    });
  }
};
