import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Form, Input, notification, Select } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forget_password } from "../../store/actions/auth";
import { getCountries } from "../../store/actions/constants";
import logo from '../../assets/images/logo.svg'
const { Option } = Select;

const ForgetPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataCountries, loading:loadingCountries } = useSelector((state) => state.countries);
  const { error, loading } = useSelector((state) => state.restPass);
  
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'تم تسجيل دخولك بنجاح',
      duration: 2
    })
  }

  const onFinish = (values) => {
    dispatch(
      forget_password({
        values,
        callback: () => {
          navigate("/verify-password" , {
            state : {
              code : values.code,
              mobile : values.mobile
            }
          });
          // openNotificationWithIcon('success')
        },
      })
    );
  };


  useEffect(() => {
    dispatch(
      getCountries()
    )
  }, []);

  if (JSON.parse(localStorage.getItem("user"))?.account.is_verified_mobile === true) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title> أجرها | استعادة كملة المرور </title>
      </Helmet>
      <header className="main-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-1">إستعادة كلمة المرور</h3>
        <h5 className="text-gray mb-4">أدخل رقم هاتفك</h5>
        {error ? <Alert className="mb-3" message={error} type="error" /> : null}
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="الدولة"
            name="code"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Select allowClear loading={loadingCountries} placeholder="الدولة">
              {dataCountries?.data?.map((item, index) => (
                <Option key={index} value={item.code}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="رقم الجوال"
            name="mobile"
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: "الرجاء ادخال رقم جوال صحيح",
              },
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="رقم الجوال" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="black" block htmlType="submit">
            إرسال
            </Button>
          </Form.Item>
          <div className="text-center">
          هل لديك حساب بالفعل ؟  <Link to="/login" className="text-dark">  قم بتسجيل الدخول </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ForgetPassword;
