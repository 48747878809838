import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getWhats } from "../../store/actions/constants";

const Account = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { whats } = useSelector((state) => state.whatsapp);

  useEffect(() => {
    dispatch(getWhats());
  }, [dispatch]);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.removeItem('user')
    navigate("/login");
    window.location.reload()
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>السيارات - Car </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8}><h4 className="font-bold">{JSON.parse(localStorage.getItem("user")).account.name}</h4></Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        <Link className="widget__item-link" to="/edit-profile">
          <div className="widget__item-icon me-2">
            <i className="icon-edit"></i>
          </div>
          <div className="widget__item-title">تعديل الملف الشخصي</div>
        </Link>
        <Link className="widget__item-link" to="/privacy">
          <div className="widget__item-icon me-2">
            <i className="icon-lock"></i>
          </div>
          <div className="widget__item-title">سياسة الخصوصية</div>
        </Link>
        <Link className="widget__item-link" to="/tickets">
          <div className="widget__item-icon me-2">
            <i className="icon-support"></i>
          </div>
          <div className="widget__item-title">الدعم الفني</div>
        </Link>
        <Link className="widget__item-link" to="/faq">
          <div className="widget__item-icon me-2">
            <i className="icon-question"></i>
          </div>
          <div className="widget__item-title">الأسئلة الشائعة</div>
        </Link>
        <Link className="widget__item-link" onClick={() => {
              const url = `http://api.whatsapp.com/send?phone=${whats}`;
              window.open(url);
            }}>
          <div className="widget__item-icon me-2">
            <i className="icon-whats"></i>
          </div>
          <div className="widget__item-title">تواصل على الواتس اب</div>
        </Link>
        <Link className="widget__item-link" to="/social-media">
          <div className="widget__item-icon me-2">
            <i className="icon-users"></i>
          </div>
          <div className="widget__item-title">
            تابعنا على شبكات التواصل الإجتماعي
          </div>
        </Link>
        <Link className="widget__item-link" to="/about">
          <div className="widget__item-icon me-2">
            <i className="icon-exclamation1"></i>
          </div>
          <div className="widget__item-title">عن التطبيق</div>
        </Link>
        {/* <Link className="widget__item-link" to="">
          <div className="widget__item-icon me-2">
            <i className="icon-list"></i>
          </div>
          <div className="widget__item-title">التسجيل كشركة</div>
        </Link> */}
        <Link className="widget__item-link btn-logout" onClick={showModal}>
          <div className="widget__item-icon me-2">
            <i className="icon-logout"></i>
          </div>
          <div className="widget__item-title">تسجيل الخروج</div>
        </Link>
        <Modal footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            نعم
          </Button>,
          <Button key="back" onClick={handleCancel}>
            لا
          </Button>,
        ]} 
        width={315} className="modal-custom" title="هل تريد تسجيل الخروج بالفعل؟" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          
        </Modal>
      </div>
    </>
  );
};

export default Account;
