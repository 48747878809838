import { Col, Collapse, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFaq } from "../../../store/actions/faq";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const { Panel } = Collapse;

const Faq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataFaq, loading, error } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(getFaq());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>   أجرها | الاسئلة الشائعة </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">الاسئلة الشائعة</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? 'حدث خطأ ما يرجى المحاولة لاحقا' : (
          <>
            {dataFaq?.data?.length ? (
              <Collapse accordion>
                {dataFaq?.data.map((item, index) => (
                  <Panel header={item.question} key={index}>
                    <p>{item.answer}</p>
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <h5>لا توجد بيانات</h5>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Faq;
