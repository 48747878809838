import { Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EmptyData from "../../components/EmptyData";
import { getSocialMedia } from "../../store/actions/constants";
import snapchat from "../../assets/images/svg/snap-chat.svg";
import twitter from "../../assets/images/svg/twitter.svg";
import tiktok from "../../assets/images/svg/tiktok.svg";
import instagram from "../../assets/images/svg/instagram.svg";
import facebook from "../../assets/images/svg/facebook.svg";
import telegram from "../../assets/images/svg/telegram.svg";
import chevron from "../../assets/images/svg/chevron-right.svg";
const SocialMedia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataSocialMedia, loading, error } = useSelector(
    (state) => state.socialMedia
  );

  useEffect(() => {
    dispatch(getSocialMedia());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> أجرها | وسائل التواصل الاجتماعي </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={6} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={12} className="text-center">تابعنا عبر الشبكات الاجتماعية</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? (
          error
        ) : (
          <>
            {/* {dataTicketsDetails?.messages.map((item, index) => ( */}
            {dataSocialMedia ? (
              <div>
                {dataSocialMedia?.data?.map((item, index) => (
                  <a
                    href={
                      item.key === "snapchat"
                        ? item.link
                        : item.key === "twitter"
                        ? item.link
                        : item.key === "tiktok"
                        ? item.link
                        : item.key === "instagram"
                        ? item.link
                        : item.key === "facebook"
                        ? item.link
                        : item.key === "telegram"
                        ? item.link
                        : null
                    }
                    className="widget__item-media"
                  >
                    <div className="widget__item-icon">
                      <img
                        className="me-2"
                        src={item.key === "snapchat"
                        ? snapchat
                        : item.key === "twitter"
                        ? twitter
                        : item.key === "tiktok"
                        ? tiktok
                        : item.key === "instagram"
                        ? instagram
                        : item.key === "facebook"
                        ? facebook
                        : item.key === "telegram"
                        ? telegram
                        : null}
                        alt=""
                      />
                    </div>
                    <h6 className="widget__item-title font-medium">
                      {item.key === "snapchat"
                        ? item.name
                        : item.key === "twitter"
                        ? item.name
                        : item.key === "tiktok"
                        ? item.name
                        : item.key === "instagram"
                        ? item.name
                        : item.key === "facebook"
                        ? item.name
                        : item.key === "telegram"
                        ? item.name
                        : null}
                    </h6>
                  </a>
                ))}
              </div>
            ) : (
              <EmptyData />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SocialMedia;
