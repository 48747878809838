import { CITIES_ERROR, CITIES_REQUEST, CITIES_SUCCESS, COUNTRIES_ERROR, COUNTRIES_REQUEST, COUNTRIES_SUCCESS, SOCIALMEDIA_ERROR, SOCIALMEDIA_REQUEST, SOCIALMEDIA_SUCCESS, TICKETTYPES_ERROR, TICKETTYPES_REQUEST, TICKETTYPES_SUCCESS, WHATS_SUCCESS } from "../../actions/constants/types";


const StateTicketTypes = {
  dataTicketTypes: null,
  loading: false,
  error: "",
};


const StateCountries = {
  dataCountries: null,
  loading: false,
  error: "",
};


const StateCities = {
  whats: null,
  loading: false,
  error: "",
};


const StateSocialMedia = {
  dataSocialMedia: null,
  loading: false,
  error: "",
};


const StateWhatsapp = {
  whats: null,
  loading: false,
  error: "",
};

export const countries = function (state = StateCountries, action) {
  switch (action.type) {
    case COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCountries:action.payload
      }; 
    case COUNTRIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 

export const ticketTypes = function (state = StateTicketTypes, action) {
  switch (action.type) {
    case TICKETTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TICKETTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTicketTypes:action.payload
      }; 
    case TICKETTYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 

export const cities = function (state = StateCities, action) {
  switch (action.type) {
    case CITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCities:action.payload
      }; 
    case CITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 
export const socialMedia = function (state = StateSocialMedia, action) {
  switch (action.type) {
    case SOCIALMEDIA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SOCIALMEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        dataSocialMedia:action.payload
      }; 
    case SOCIALMEDIA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 

export const whatsapp = function (state = StateWhatsapp, action) {
  switch (action.type) {
    case WHATS_SUCCESS:
      return {
        ...state,
        loading: false,
        whats:action.payload
      }; 
    default:
      return state;
  }
}

// 