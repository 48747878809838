import React from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Form, Input } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verify_password } from "../../store/actions/auth";
import chevron from "../../assets/images/svg/chevron-right.svg";

const VerifyPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation();

  const { error, loading } = useSelector((state) => state.verifyPassword);
  
  

  const onFinish = (values) => {
    values = {
      ...values,
      mobile: state?.code+state?.mobile.substring(1)
     };
     dispatch(
      verify_password({
         values, 
         callback: () => {
          navigate(`/change-password`, {
            state: {
              code: state?.code,
              mobile: state?.mobile,
            },
          });
         },
       })
     );
  };


  if (!state?.mobile || !state?.code) {
    return <Navigate to="/forget-password" />;
  }else if(JSON.parse(localStorage.getItem("user"))?.account.is_verified_mobile === true){
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title> أجرها | استعادة كلمة المرور </title>
      </Helmet>
      <header className="main-header">
        <div className="p-1 pointer icon" onClick={() => navigate(-1)}>
          <img src={chevron} alt="logo" />
        </div>
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-1">تأكيد</h3>
        <h5 className="text-gray mb-4">تم إرسال المصادقة إلى رقم {state?.mobile}</h5>
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          onFinish={onFinish}
        >
          <Form.Item
            label="الرمز"
            name="verification_code"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="رمز التحقق" />
          </Form.Item>
                  
          <Form.Item>
            <Button loading={loading} type="black" block htmlType="submit">
              تأكيد
            </Button>
          </Form.Item>
          {error ? <Alert type="error" message={error} /> : null}
        </Form>
      </div>
    </>
  );
};

export default VerifyPassword;
