import 'antd/dist/reset.css';
import Layout from './components/Layout/Layout';
import './styles/main.scss'

function App() {
  return (
      <Layout />
  );
}

export default App;
