import { REPLAY_ERROR, REPLAY_REQUEST, REPLAY_SUCCESS, TICKETSDETAILS_ERROR, TICKETSDETAILS_REQUEST, TICKETSDETAILS_SUCCESS, TICKETSTORE_ERROR, TICKETSTORE_REQUEST, TICKETSTORE_SUCCESS, TICKETS_ERROR, TICKETS_REQUEST, TICKETS_SUCCESS } from "../../actions/tickets/types";

const StateTickets = {
  dataTickets: null,
  loading: false,
  error: "",
};

const StateTicketsDetails = {
  dataTicketsDetails: null,
  loading: false,
  error: "",
};
// 
const StateTicketStore = {
  loading: false,
  error: "",
};
// 
const StateReplayTicket = {
  loading: false,
  error: "",
};
// 


export const tickets = function (state = StateTickets, action) {
  switch (action.type) {
    case TICKETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTickets:action.payload
      }; 
    case TICKETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 


export const ticketsDetails = function (state = StateTicketsDetails, action) {
  switch (action.type) {
    case TICKETSDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TICKETSDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTicketsDetails:action.payload
      }; 
    case TICKETSDETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 


export const ticketStore = function (state = StateTicketStore, action) {
  switch (action.type) {
    case TICKETSTORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TICKETSTORE_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case TICKETSTORE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 

export const replayTicket = function (state = StateReplayTicket, action) {
  switch (action.type) {
    case REPLAY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REPLAY_SUCCESS:
      return {
        ...state,
        loading: false,
      }; 
    case REPLAY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 
