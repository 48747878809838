import { Alert, Button, Col, Divider, Form, Input, notification, Row, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import EmptyData from "../../../components/EmptyData";
import file from "../../../assets/images/svg/file.svg";
import { getTicketsDetails, replay } from "../../../store/actions/tickets";
import { saveAs } from "file-saver";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const TicketsDetails = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate()

  let { id } = useParams();

  const [picture, setPicture] = useState();
  const [imgData, setImgData] = useState(null);

  const [form] = Form.useForm();

  const { dataTicketsDetails, loading, error } = useSelector(
    (state) => state.ticketsDetails
  );


  const { loading:loadingReply, error:errorReply } = useSelector(
    (state) => state.replayTicket
  );


  const messagesEndRef = useRef(null)
  
  
  useEffect(() => {
    dispatch(getTicketsDetails({id ,callback:() =>{messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })}  }));
  }, [dispatch]);

  
  useEffect(() => {
  }, [dataTicketsDetails]);

  const download = (image) => {
    saveAs(image, "image");
  };


  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'تم ارسال ردك بنجاح',
      duration: 2
    })
  }

  const onSubnmit = (values) => {
    values = {
     ...values,
     id : id,
     file : picture,
    };
    dispatch(
      replay({
        values, 
        callback: () => {
          openNotificationWithIcon("success");
          dispatch(getTicketsDetails({id ,callback:() =>{messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })}  }));
          form.resetFields();
          setImgData(null);
          setPicture();
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        },
      })
    );
  };

  const onChangePicture = e => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

 



  return (
    <>
      <Helmet>
        <title> أجرها | الدعم الفني  </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">الدعم الفني</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        <div>
          {loading ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : error ? (
            <Alert type="error" message={error} />
          ) : (
            <>
              {!!dataTicketsDetails && dataTicketsDetails ? (
                <div>
                  <div className="widget__item-ticket-single border-primary">
                    <div className="widget__item-head d-flex align-items-center justify-content-between">
                      <h6 className="font-12 font-medium">
                        {dataTicketsDetails.title}
                      </h6>
                      <h6 className="font-12 status-primary">
                        {dataTicketsDetails.id}
                      </h6>
                    </div>
                    <div className="widget__item-body">
                      <h6 className="font-12">{dataTicketsDetails.content}</h6>
                      {dataTicketsDetails.attachments?.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => download(item.image)}
                          className="widget__item-footer pointer d-flex align-items-center justify-content-between"
                        >
                          <h6 className="font-12">المرفقات</h6>
                          <div className="d-flex align-items-center">
                            <h6 className="font-medium ml-2">
                              {index + 1}
                            </h6>
                            <img src={file} alt="" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {dataTicketsDetails?.messages.map((item, index) => (
                    <div className="mb-3" key={index}>
                      <div
                        className={`widget__item-ticket-single ${
                          item.by === "مستخدم" ? "border-primary" : null
                        } `}
                      >
                        <div className="widget__item-head d-flex align-items-center justify-content-between">
                          <h6 className="font-12 font-medium">{item.by}</h6>
                          <h6 className="font-12 state-primary">
                            {item.created_at}
                          </h6>
                        </div>
                        <div className="widget__item-body">
                          <h6 className="font-12">{item.text}</h6>
                          {item.attachments?.map((item, index) => (
                            <div
                              key={index}
                              onClick={() => download(item.image)}
                              className="widget__item-footer pointer d-flex align-items-center justify-content-between"
                            >
                              <h6 className="font-12">المرفقات</h6>
                              <div className="d-flex align-items-center">
                                <h6 className="font-medium ml-2">
                                  {index + 1}
                                </h6>
                                <img src={file} alt="" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <EmptyData />
              )}
            </>
          )}
        </div>
        <Divider />
        <div ref={messagesEndRef}></div>
        <Form  size="large" onFinish={onSubnmit} autoComplete="off"  form={form} labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}>
          <Form.Item
            label="ردي"
            name="text"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب!",
              },
            ]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>


          {/* <Form.Item  getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "الحقل مطلوب!",
              },
            ]}
            name="file"
            label="افاق صورة"
          >
            <Upload name="file" beforeUpload={() => false}>
              <Button>ارفاق ملفات</Button>
            </Upload>
          </Form.Item> */}

          <label htmlFor="profilePic" className="uploadImage">
            <input id="profilePic" name="license_file" accept="/image/*" type="file" onChange={(e) => {
                  onChangePicture(e);
                }} />
            <span className="form-label">المرفقات </span>
          </label>
          <div className="py-2">
            <img className="imgData" src={imgData} />
          </div>

          <Form.Item>
            <Button loading={loadingReply} type="yellow" block htmlType="submit">
              ارسال
            </Button>
          </Form.Item>
          {errorReply ? <Alert type="danger"  message={errorReply} /> : null }
        </Form>
        
      </div>
    </>
  );
};

export default TicketsDetails;
