
// PRIVACY
export const PRIVACY_REQUEST = 'PRIVACY_REQUEST';
export const PRIVACY_SUCCESS = 'PRIVACY_SUCCESS'
export const PRIVACY_ERROR = 'PRIVACY_ERROR'



// ABOUT
export const ABOUT_REQUEST = 'ABOUT_REQUEST';
export const ABOUT_SUCCESS = 'ABOUT_SUCCESS'
export const ABOUT_ERROR = 'ABOUT_ERROR'


