import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import logo from '../../assets/images/logo.svg';
import cross from '../../assets/images/cross.png';

const PaymentFailed = () => {
  return (
    <>
      <Helmet>
        <title> أجرها | الدفع </title>
      </Helmet>
      <header className="main-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content">
        <div className="text-center">
          <div className="mb-4">
            <img src={cross} alt="" />
          </div>  
          <h4 className="font-bold mb-2 text-success">لم تتم عملية الدفع بنجاح</h4>
          <h6 className="font-bold mb-2 text-success">حاول مرة ثانية</h6>
          <Link to="/" className="ant-btn-yellow">الرئيسية</Link>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
