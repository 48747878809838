import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, updateProfile } from "../../store/actions/profile";
import dayjs from "dayjs";
import chevron from "../../assets/images/svg/chevron-right.svg";
import { getCountries } from "../../store/actions/constants";
const { Option } = Select;

const EditProfile = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataCountries, loading:loadingCountries } = useSelector((state) => state.countries);
  const [picture, setPicture] = useState();
  const [imgData, setImgData] = useState(null);

  const { dataProfile } = useSelector((state) => state.profile);
  const { loading: loadingUpdateProfile, error: errorUpdateProfile } =
    useSelector((state) => state.updateProfile);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "تم تعديل البيانات بنجاح",
      duration: 2,
    });
  };

  const onFinish = (values) => {
    values = {
      ...values,
      license_file : picture
    }
    dispatch(
      updateProfile({
        values,
        callback: () => {
          window.location.reload()
          dispatch(getProfile());
          openNotificationWithIcon('success')
         },
      })
    );
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(
      getCountries()
    )
  }, []);


  useEffect(() => {
    console.log(picture)
  }, [picture]);


  const onChangePicture = e => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <Helmet>
        <title> أجرها | تعديل الملف الشخصي </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)}>
            <img className="icon" src={chevron} alt="" />
          </Col>
          <Col xs={8} className="text-center">
            الملف الشخصي
          </Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-3">اكمال البيانات</h3>
        {errorUpdateProfile ? (
          <Alert message={errorUpdateProfile} className="mb-3" type="error" />
        ) : null}
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          onFinish={onFinish}
          fields={[
            {
              name: ["name"],
              value: dataProfile?.name,
            },
            {
              name: ["mobile"],
              value: dataProfile?.mobile,
            },
            {
              name: ["identity"],
              value: dataProfile?.identity,
            },
            {
              name: ["license_file"],
              value: picture,
            },
            {
              name: ["code"],
              value: dataProfile?.country.code,
            },
            {
              name: ["birth_date"],
              value: dayjs("2015/01/01", !!dataProfile?.birth_date),
            },
          ]}
        >
          <Form.Item
            label="الاسم"
            name="name"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="الاسم" />
          </Form.Item>

          <Form.Item
            label="تاريخ الميلاد"
            name="birth_date"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <DatePicker
              suffix="s"
              style={{ width: "100%" }}
              placeholder="ادخل تاريخ الميلاد"
            />
          </Form.Item>

          <Form.Item
            label="الدولة"
            name="code"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Select allowClear loading={loadingCountries} placeholder="الدولة">
              {dataCountries?.data?.map((item, index) => (
                <Option key={index} value={item.code}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="رقم الجوال"
            name="mobile"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="رقم الهوية/الاقامة"
            name="identity"
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: "الرجاء ادخال رقم جوال صحيح",
              },
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="ادخل رقم الهوية/الاقامة" />
          </Form.Item>

          <Form.Item
            label="كلمة المرور"
            name="password"
          >
            <Input placeholder="ضع كلمة المرور الجديدة" />
          </Form.Item>

          {/* <Form.Item
            className="image-block"
           
            rules={[
              {
                required: true,
                message: "الحقل مطلوب!",
              },
            ]}
            name="license_image"
            label="صورة الرخصة"
          >
            
          </Form.Item> */}
          <label htmlFor="profilePic" className="uploadImage mb-3">
            <input id="profilePic" name="license_file" accept="/image/*" type="file" onChange={(e) => {
                  onChangePicture(e);
                }} />
            <span className="form-label">صورة الرخصة</span>
          </label>
          {imgData ? (
            <div className="mb-3">
            <img className="imgData" src={imgData} />
          </div>
          ) : null}
          {dataProfile?.license_image ? (
            <div className="mb-4">
              <img src={dataProfile?.license_image} className="imgData" alt="" />
            </div>
          ) : null}

          <Form.Item>
            <Button
              loading={loadingUpdateProfile}
              className="ant-btn-yellow"
              block
              htmlType="submit"
            >
              موافق
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default EditProfile;
