import {
  COUNTRIES_ERROR,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  TICKETTYPES_ERROR,
  TICKETTYPES_REQUEST,
  TICKETTYPES_SUCCESS,
  CITIES_ERROR,
  CITIES_REQUEST,
  CITIES_SUCCESS,
  SOCIALMEDIA_ERROR,
  SOCIALMEDIA_SUCCESS,
  SOCIALMEDIA_REQUEST,
  WHATS_SUCCESS,
} from "./types.js";
import axiosApp from "../../../api/index.js";

export const getTicketTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: TICKETTYPES_REQUEST,
    });
    const response = await axiosApp.get(`/constants/ticket-types`);
    dispatch({
      type: TICKETTYPES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: TICKETTYPES_ERROR,
      payload: error.response.data.message,
    });
  }
};

//

export const getCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: COUNTRIES_REQUEST,
    });
    const response = await axiosApp.get(`/constants/countries`);
    dispatch({
      type: COUNTRIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNTRIES_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const getCities = () => async (dispatch) => {
  try {
    dispatch({
      type: CITIES_REQUEST,
    });
    const response = await axiosApp.get(`/constants/cities`);
    dispatch({
      type: CITIES_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: CITIES_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const getSocialMedia = () => async (dispatch) => {
  try {
    dispatch({
      type: SOCIALMEDIA_REQUEST,
    });
    const response = await axiosApp.get(`/constants/social-media`);
    dispatch({
      type: SOCIALMEDIA_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: SOCIALMEDIA_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const getWhats = () => async (dispatch) => {
  try {
    const response = await axiosApp.get(`/constants/index`);
    const number = response.data.data.filter((e) => {
      return e.key === "whatsapp_mobile";
    });
    dispatch({
      type: WHATS_SUCCESS,
      payload: number[0].value,
    });
  } catch (error) {
  }
};
