import { ABOUT_ERROR, ABOUT_REQUEST, ABOUT_SUCCESS, PRIVACY_ERROR, PRIVACY_REQUEST, PRIVACY_SUCCESS } from "../../actions/pages/types";

const StatePrivacy = {
  dataPrivacy: null,
  loading: false,
  error: "",
};
// 


const StateAbout = {
  dataAbout: null,
  loading: false,
  error: "",
};
// 


export const privacy = function (state = StatePrivacy, action) {
  switch (action.type) {
    case PRIVACY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        dataPrivacy:action.payload
      }; 
    case PRIVACY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 

export const about = function (state = StateAbout, action) {
  switch (action.type) {
    case ABOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ABOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAbout:action.payload
      }; 
    case ABOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 