import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Form, Input, notification, Select } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth";
import { getCountries } from "../../store/actions/constants";
import logo from '../../assets/images/logo.svg'

const { Option } = Select;

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataCountries, loading:loadingCountries } = useSelector((state) => state.countries);
  const { error, loading , mobile } = useSelector((state) => state.logedIn);
  
  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'تم تسجيل دخولك بنجاح',
      duration: 2
    })
  }

  const onFinish = (values) => {
    dispatch(
      login({
        values,
        callback: () => {
          navigate("/");
          openNotificationWithIcon('success')
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      getCountries()
    )
  }, []);


  useEffect(() => {
    if(mobile){
      navigate(`/register/verify`, {
        state: {
          mobile: mobile,
        },
      });
    }
  }, [mobile]);


  if (JSON.parse(localStorage.getItem("user"))?.account.is_verified_mobile === true) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <title> أجرها | تسجيل الدخول  </title>
      </Helmet>
      <header className="main-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-1">مرحبا</h3>
        <h5 className="text-gray mb-4">ادخل رقم هاتفك للدخول الى حسابك</h5>
        {error ? <Alert message={error} className="mb-3" type="error" /> : null}
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            label="الدولة"
            name="code"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Select allowClear loading={loadingCountries} placeholder="الدولة">
              {dataCountries?.data?.map((item, index) => (
                <Option key={index} value={item.code}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="رقم الجوال"
            name="mobile"
            rules={[
              {
                pattern: /^(?:\d*)$/,
                message: "الرجاء ادخال رقم جوال صحيح",
              },
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="رقم الجوال" />
          </Form.Item>

          <Form.Item
            label="كلمة المرور"
            name="password"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input.Password placeholder="كلمة المرور" />
          </Form.Item>
          <h6 className="font-medium text-underline mb-3">
            <Link to="/forget-password" className="text-dark">
              هل نسيت كلمة المرور؟
            </Link>
          </h6>
          <Form.Item>
            <Button loading={loading} type="black" block htmlType="submit">
              تسجيل الدخول
            </Button>
          </Form.Item>
          <div className="text-center">
          ليس لديك حساب ؟  <Link to="/register" className="text-dark"> سجل الان </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
