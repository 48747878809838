import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div className="app-footer">
      <ul className="nav-footer">
        <li>
          <Link to="/">
            <i className="icon-search"></i>
          </Link>
        </li>
        <li>
          <Link to="/order">
            <i className="icon-order"></i>
          </Link>
        </li>
        <li>
          <Link to="/notifications" className="active">
            <i className="icon-bell"></i>
          </Link>
        </li>
        <li>
          <Link to="/account">
            <i className="icon-user"></i>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
