import { Alert, Col, Divider, Modal, Radio, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import EmptyData from "../../components/EmptyData";
import { getOrder } from "../../store/actions/order";
import chevron from "../../assets/images/svg/chevron-right.svg";

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(false);
  const { dataOrder, loading, error } = useSelector((state) => state.order);


  useEffect(() => {
    dispatch(
      getOrder({
        params: {
          state:'coming'
        },
      })
    );
  }, []);

  const onChange = (e) => {
    dispatch(
      getOrder({
        params: {
          state:e.target.value
        },
      })
    )
  };


  
  useEffect(() => {
    console.log(data)
  }, [data]);

  const showModal = (item) => {
    setIsModalOpen(true);
    setData(item)
    console.log(item)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> أجرها | طلباتي </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">طلباتي</Col>
        </Row>
      </header>
      <div className="app-content">
        <div className="tab-order text-center">
          <Radio.Group onChange={onChange} defaultValue="coming">
            <Radio.Button value="coming">قادمة</Radio.Button>
            <Radio.Button value="open">مفتوحة</Radio.Button>
            <Radio.Button value="ended">منتهية</Radio.Button>
          </Radio.Group>
        </div>
        <div className="mt-2 mb-2">
          {loading ? (
           <div className="text-center">
             <Spin />
           </div>
          ) : error ? (
            <Alert type="danger" message={error} />
          ) : (
            <div>
              {dataOrder?.data?.length ? (
                <div>
                  {dataOrder?.data?.map((item, index) => (
                    <div className="widget__item-order d-flex align-items-center" key={index}  onClick={(e)=> showModal(item)}>
                      <div className="widget__item-image"> <img src={item.advertisement?.image} alt="" /></div>
                      <div className="widget__item-content">
                        <h6 className="font-bold mb-1">{item.advertisement?.title}</h6>
                        {/* <h6 className="text-gray font-12">2021</h6> */}
                        <h6 className="font-bold">رقم الطلب : {item.id}</h6>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <EmptyData />
              )}
            </div>
          )}
        </div>
        <Modal className="ant-modal-order" width={330} title={false} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

          <div class="widget__item-car mb-3 border-0">
            <div class="widget__item-image image-large text-center"><a href=""> <img src={data.advertisement?.image} alt={data.advertisement?.title} /></a></div>
            <div className="px-3 pt-3 pb-5">
              <h6 className="font-bold mb-2 mt-3">{data.advertisement?.title}</h6>
              <div class="d-flex align-items-center"> 
                <div class="col-auto"><img class="widget__item-imgCompany" src={data.advertisement?.company.image} alt={data.advertisement?.company.name} /></div>
                <h6 class="ms-2 font-10">{data.advertisement?.company.name}</h6>
              </div>
              <Divider />
              <Row>
                <Col span={12}>
                  <h6 class="font-12">من /</h6>
                  <h6 class="text-gray font-12">{data?.from}</h6>
                  <h6 class="text-gray font-12">{data?.advertisement?.city?.name}</h6>
                </Col>
                <Col span={12}>
                <h6 class="font-12">الى /</h6>
                  <h6 class="text-gray font-12">{data?.to}</h6>
                  <h6 class="text-gray font-12">{data?.advertisement?.city?.name}</h6>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Order;
