

import axiosApp from "../../../api/index.js";
import { NOTIFICATIONS_ERROR, NOTIFICATIONS_REQUEST, NOTIFICATIONS_SUCCESS } from "./types.js";

export const getNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: NOTIFICATIONS_REQUEST,
    });
    const response = await axiosApp.get(`/account/notifications`);
    dispatch({
      type: NOTIFICATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_ERROR,
      payload: error.response.data.message,
    });
  }
};
