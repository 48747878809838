import {
  CARDETAILS_ERROR,
  CARDETAILS_REQUEST,
  CARDETAILS_SUCCESS,
  CAR_ERROR,
  CAR_REQUEST,
  CAR_SUCCESS,
  CATEGORIES_ERROR,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  SUBCATEGORIES_ERROR,
  SUBCATEGORIES_REQUEST,
  SUBCATEGORIES_SUCCESS,
} from "../../actions/car/types";

const StateCars = {
  dataCars: null,
  loading: false,
  error: "",
};
//

//
const StateCarDetails = {
  dataCarDetails: null,
  loading: false,
  error: "",
};
//

const StateSubcategories = {
  dataSubcategories: null,
  loading: false,
  error: "",
};
//
const StateCategories = {
  dataCategories: null,
  loading: false,
  error: "",
};
//

export const cars = function (state = StateCars, action) {
  switch (action.type) {
    case CAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CAR_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCars: action.payload,
      };
    case CAR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//

export const carDetails = function (state = StateCarDetails, action) {
  switch (action.type) {
    case CARDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CARDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCarDetails: action.payload,
      };
    case CARDETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//

export const subcategories = function (state = StateSubcategories, action) {
  switch (action.type) {
    case SUBCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataSubcategories: action.payload,
      };
    case SUBCATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//


export const categories = function (state = StateCategories, action) {
  switch (action.type) {
    case CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCategories: action.payload,
      };
    case CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//



