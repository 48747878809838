

import { FAQ_ERROR, FAQ_REQUEST, FAQ_SUCCESS } from "./types.js";
import axiosApp from "../../../api/index.js";

export const getFaq = () => async (dispatch) => {
  try {
    dispatch({
      type: FAQ_REQUEST,
    });
    const response = await axiosApp.get(`/faq/list`);
    dispatch({
      type: FAQ_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FAQ_ERROR,
      payload: error.response.data.message,
    });
  }
};



