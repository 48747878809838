
// PROFILE
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_ERROR = 'PROFILE_ERROR'


// UPDATEPROFILE_REQUEST
export const UPDATEPROFILE_REQUEST = 'UPDATEPROFILE_REQUEST';
export const UPDATEPROFILE_SUCCESS = 'UPDATEPROFILE_SUCCESS'
export const UPDATEPROFILE_ERROR = 'UPDATEPROFILE_ERROR'