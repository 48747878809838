import { NOTIFICATIONS_ERROR, NOTIFICATIONS_REQUEST, NOTIFICATIONS_SUCCESS } from "../../actions/notifications/types";

const StateNotifications = {
  dataNotifications: null,
  loading: false,
  error: "",
};
// 


export const notifications = function (state = StateNotifications, action) {
  switch (action.type) {
    case NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataNotifications:action.payload
      }; 
    case NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 