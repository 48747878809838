import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Account from "../pages/Account";
import CarDetails from "../pages/Car/Details";
import CarList from "../pages/Car/List";
import Faq from "../pages/Faq/List";
import ForgetPassword from "../pages/ForgetPassword";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Notifications from "../pages/Notifications/List";
import Order from "../pages/Order";
import PaymentDetails from "../pages/Payment/Details";
// import OnlinePayment from "../pages/Payment/OnlinePayment";
import Privacy from "../pages/Privacy";
import Register from "../pages/Register";
import RegisterVerify from "../pages/RegisterVerify";
import SocialMedia from "../pages/SocialMedia";
import TicketsِAdd from "../pages/Tickets/Add";
import TicketsDetails from "../pages/Tickets/Details";
import TicketsList from "../pages/Tickets/List";
import PrivateRoutes from './ProtectedRoute'
import EditProfile from "../pages/EditProfile";
import VerifyPassword from "../pages/VerifyPassword";
import PageNotFound from "../pages/PageNotFound";
import ChangePassword from "../pages/ChangePassword";
import PaymentFailed from "../pages/Payment/PaymentFailed";
import PaymentSuccess from "../pages/Payment/PaymentSuccess";
// import Payment from "../pages/Payment";
// import PaymentPage from "../pages/payments";

const Routers = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/register" element={<Register />}></Route>
      <Route path="/register/verify" element={<RegisterVerify />}></Route>
      <Route path="/forget-password" element={<ForgetPassword />}></Route>
      <Route path="/verify-password" element={<VerifyPassword />}></Route>
      <Route path="/change-password" element={<ChangePassword />}></Route>
      <Route element={<PrivateRoutes/>}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/payment/details" element={<PaymentDetails />}></Route>
        <Route path="/payment/failed" element={<PaymentFailed />}></Route>
        <Route path="/payment/success" element={<PaymentSuccess />}></Route>
        {/* <Route path="/payment/online" element={<OnlinePayment />}></Route> */}
        {/* <Route path="/payment/index" element={<Payment />}></Route> */}
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/tickets" element={<TicketsList />}></Route>
        <Route path="/tickets/:id" element={<TicketsDetails />}></Route>
        <Route path="/tickets/add" element={<TicketsِAdd />}></Route>
        <Route path="/cars" element={<CarList />} />
        <Route path="/cars/:id" element={<CarDetails />}></Route>
        <Route path="/notifications" element={<Notifications />}></Route>
        <Route path="/account" element={<Account />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/social-media" element={<SocialMedia />}></Route>
        <Route path="/order" element={<Order />}></Route>
        <Route path="/edit-profile" element={<EditProfile />}></Route> 
        {/* <Route path="/payment" element={<PaymentPage />}></Route> */}

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Routers;
