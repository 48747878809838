import { Col, Empty, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getCarDetails } from "../../../store/actions/car";
import dollar from "../../../assets/images/svg/dollar.svg";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const CarDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [accessoriesArray, setAccessoriesArray] = useState([]);


  let { id } = useParams();

  const { state } = useLocation();

  const { dataCarDetails, loading, error } = useSelector(
    (state) => state.carDetails
  );
  const [accessoriesArray, setAccessoriesArray] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    dispatch(getCarDetails(id));
    console.log(state)
  }, [dispatch]);


  useEffect(() => {
    console.log(accessoriesArray.reduce((n, {cost}) => n + cost, 0))
  }, [accessoriesArray]);

 
  
  if(state === null){
    navigate('/')
  }

  return (
    <>
      <Helmet>
        <title> أجرها | سيارات </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <div onClick={() => navigate(-1)} ><img src={chevron} className="p-1 pointer icon" /></div>
        </Row>
      </header>
      <div className="app-content">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? (
          error
        ) : (
          <>
            {!!dataCarDetails ? (
              <div>
                <h3> {dataCarDetails.title}</h3>
                <div className="car-single-image text-center mb-4">
                  <img src={dataCarDetails.image} alt={dataCarDetails.name} />
                </div>
                <Row gutter={10}>
                  {dataCarDetails?.accessories?.map((item, index) => (
                    <Col span={8}>

                    <label className="widget__item-serv">
                      <input
                          onClick={(e) => {
                            // add to list
                            if (e.target.checked) {
                              setAccessoriesArray([
                                ...accessoriesArray,
                                {
                                  id: item.id,
                                  name: item.name,
                                  cost: item.cost,
                                },
                              ]);
                            } else {
                              // remove from list
                              setAccessoriesArray(
                                accessoriesArray.filter((people) => people.id !== item.id),
                              );
                            }
                          }}
                          type="checkbox"
                        />
                        <div className="widget__item-serv-content">
                        <div className="widget__item-icon mb-1">
                            <img
                              src={dollar}
                              alt={item.name}
                              style={{
                                width: "1.5rem",
                                height: "auto",
                              }}
                            />
                          </div>
                          <h6 className="widget__item-price mb-1">
                            <span className="font-medium">{item.cost}</span>
                            <span className="font-12 text-gray ms-2">ريال</span>
                          </h6>
                          <h6 className="widget__item-title font-12 text-gray">{item.name}</h6>
                        </div>
                      </label>
                    </Col>

                    ))}
                </Row>
                <Row className="mt-7">
                  <Col span={8}>
                    {dataCarDetails.discount ? (
                      <h6 className="text-gray line-through font-medium">
                        {dataCarDetails.discount}
                      </h6>
                    ) : null}
                    <h5>
                      <span className="font-bold">
                        {dataCarDetails.price + accessoriesArray?.reduce((n, {cost}) => n + cost, 0)}
                      </span>
                      <span className="text-gray font-12 mr-2">ريال / يوم</span>
                    </h5>
                  </Col>
                  <Col span={16}>
                    <Link
                      to="/payment/details"
                      state={{
                        price: dataCarDetails?.price,
                        discount: dataCarDetails?.discount,
                        id: dataCarDetails?.id,
                        from: state?.from,
                        to: state?.to,
                        need_delivery: 0,
                        accessories: accessoriesArray,
                        lat: state?.lat,
                        lng: state?.lng,
                        differenceInDays:state?.differenceInDays
                      }}
                      className="ant-btn-yellow btn-block"
                      size="large"
                      block
                    >
                      احجز الآن
                    </Link>
                  </Col>
                </Row>
              </div>
            ) : (
              <Empty />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CarDetails;
