// TICKETTYPES
export const TICKETTYPES_REQUEST = 'TICKETTYPES_REQUEST';
export const TICKETTYPES_SUCCESS = 'TICKETTYPES_SUCCESS';
export const TICKETTYPES_ERROR = 'TICKETTYPES_ERROR';


// COUNTRIES
export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_ERROR = 'COUNTRIES_ERROR'


// CITIES
export const CITIES_REQUEST = 'CITIES_REQUEST';
export const CITIES_SUCCESS = 'CITIES_SUCCESS';
export const CITIES_ERROR = 'CITIES_ERROR';

// SOCIALMEDIA
export const SOCIALMEDIA_REQUEST = 'SOCIALMEDIA_REQUEST';
export const SOCIALMEDIA_SUCCESS = 'SOCIALMEDIA_SUCCESS';
export const SOCIALMEDIA_ERROR = 'SOCIALMEDIA_ERROR';


// SOCIALMEDIA
export const WHATS_SUCCESS = 'WHATS_SUCCESS';