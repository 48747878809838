import { Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EmptyData from "../../../components/EmptyData";
import { getNotifications } from "../../../store/actions/notifications";
import exclamation2 from "../../../assets/images/svg/exclamation2.svg";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataNotifications, loading, error } = useSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> أجرها | الاشعارات </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">الاشعارات</Col>
        </Row>
      </header>
      <div className="app-content">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? (
          "حدث خطأ ما يرجى المحاولة لاحقا"
        ) : (
          <>
            {dataNotifications?.data?.length ? (
              <div>
                {dataNotifications?.data.map((item, index) => (
                  <Link
                    to={
                      item.data.type === 'order_inprogress'
                        ? "/order"
                        : item.data.type === 'order_delivered'
                        ? "/order"
                        : item.data.type === 'order_canceled'
                        ? "/order"
                        : item.data.type === 'reply_ticket'
                        ? "/tickets"
                        : null
                    }
                    key={index}
                    className="widget__item-notification d-flex align-items-center"
                  >
                    <div className="widget__item-icon col-auto">
                      <img src={exclamation2} alt="" />
                    </div>
                    <div className="widget__item-content mr-3">
                      <h6 className="font-medium font-12">{item.data.text}</h6>
                      <h6 className="font-12 text-gray">منذ 15 دقيقة</h6>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <EmptyData />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Notifications;
