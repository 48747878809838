import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Alert, Button, Form, Input, notification } from "antd";
import {  Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verify } from "../../store/actions/auth";
import chevron from "../../assets/images/svg/chevron-right.svg";

const RegisterVerify = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {state} = useLocation();

  const { error, loading } = useSelector((state) => state.verify);

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "تم تسجيلك بنجاح",
      duration: 2,
    });
  };

  const onFinish = (values) => {

    dispatch(
      verify({
        values,
        callback: () => {
          console.log('dsf')
          navigate("/");
          openNotificationWithIcon("success");
        },
      })
    );
  };


  console.log(localStorage.getItem("user"))


  // if (!localStorage.getItem("user") ) {
  //   return <Navigate to="/register" />;
  // }else if(JSON.parse(localStorage.getItem("user"))?.account.is_verified_mobile === true){
  //   return <Navigate to="/" />;
  // }else if(localStorage.getItem("user") === undefined ){
  //   return <Navigate to="/register" />;
  // }

  return (
    <>
      <Helmet>
        <title>تسجيل الدخول  </title>
      </Helmet>
      <header className="main-header">
        <div className="p-1 pointer" onClick={() => navigate(-1)}>
          <img src={chevron} alt="logo" className="icon" />
        </div>
      </header>
      <div className="app-content bg-light">
        <h3 className="font-bold mb-1">تأكيد</h3>
        <h5 className="text-gray mb-4">تم إرسال المصادقة إلى رقم {JSON.parse(localStorage?.getItem("user")).account?.mobile}</h5>
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          onFinish={onFinish}
        >
          <Form.Item
            label="الرمز"
            name="two_factor_code"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            <Input placeholder="رمز التحقق" />
          </Form.Item>
                  
          <Form.Item>
            <Button loading={loading} type="black" block htmlType="submit">
              تسجيل
            </Button>
          </Form.Item>
          {error ? <Alert message={error} type="error" /> : null}
        </Form>
      </div>
    </>
  );
};

export default RegisterVerify;
