
// TICKETS
export const TICKETS_REQUEST = 'TICKETS_REQUEST';
export const TICKETS_SUCCESS = 'TICKETS_SUCCESS'
export const TICKETS_ERROR = 'TICKETS_ERROR'



// TICKETS TICKETSDETAILS
export const TICKETSDETAILS_REQUEST = 'TICKETSDETAILS_REQUEST';
export const TICKETSDETAILS_SUCCESS = 'TICKETSDETAILS_SUCCESS'
export const TICKETSDETAILS_ERROR = 'TICKETSDETAILS_ERROR'

// REPLAY
export const REPLAY_REQUEST = 'REPLAY_REQUEST';
export const REPLAY_SUCCESS = 'REPLAY_SUCCESS'
export const REPLAY_ERROR = 'REPLAY_ERROR'





// TICKETTYPES
export const TICKETSTORE_REQUEST = 'TICKETSTORE_REQUEST';
export const TICKETSTORE_SUCCESS = 'TICKETSTORE_SUCCESS'
export const TICKETSTORE_ERROR = 'TICKETSTORE_ERROR'