import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Form,
  Select,
  DatePicker,
  Row,
  Col,
  TimePicker,
  Switch,
  Input,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { getCities } from "../../store/actions/constants";
import logo from '../../assets/images/logo.svg';

import 'dayjs/locale/ar';
import locale from 'antd/es/date-picker/locale/ar_EG';
import moment from "moment";
const { Option } = Select;

const Home = () => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const [lng, setLng ] = useState();
  const [lat, setLat] = useState();

  const [stateMap, setStateMap] = useState();
  const [differenceInDays, setDifferenceInDays] = useState();
  const [differenceInDaysError, setDifferenceInDaysError] = useState(false);
  const [differenceInDaysError2, setDifferenceInDaysError2] = useState(false);
  const { dataCities, loading, error } = useSelector((state) => state.cities);

  const [dateFrom, setDateFrom] = useState();
  const [clockFrom, setClockFrom] = useState();

  const [dateTo, setDateTo] = useState();
  const [clockTo, setClockTo] = useState();

  const onChangeDateFrom = (date, dateString) => {
    setDateFrom(dateString);
  };
  const onChangeClockFrom = (time, timeString) => {
    setClockFrom(timeString);
  };

  const onChangeDateTo = (date, dateString) => {
    setDateTo(dateString);
  };
  const onChangeClockTo = (time, timeString) => {
    setClockTo(timeString);
  };

  useEffect(() => {
    let date1 = new Date(dateFrom);
    let date2 = new Date(dateTo);
    const differenceInDays = Math.floor(date2 - date1) / (1000 * 3600 * 24);
    console.log(differenceInDays)
    setDifferenceInDays(differenceInDays)
  }, [dateFrom, clockFrom, dateTo, clockTo , differenceInDays]);

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);
  

  const onFinish = (values) => {
    if(dateFrom === dateTo) {
      console.log('s')
      setDifferenceInDaysError2(false)
      setDifferenceInDaysError(true)
      return
    } if (differenceInDays <= 0){
      console.log('s333')
      setDifferenceInDaysError(false)
      setDifferenceInDaysError2(true)
    }
    else{
      (!!lat && !!lng ? (
        navigate(`/cars`, {
          state: {
            city_id: values.city_id,
            from: dateFrom + " " + clockFrom,
            to: dateTo + " " + clockTo,
            differenceInDays: differenceInDays,
            lat : lat,
            lng : lng,
            need_delivery : 1
          },
        })
      ) : (
        navigate(`/cars`, {
          state: {
            city_id: values.city_id,
            from: dateFrom + " " + clockFrom,
            to: dateTo + " " + clockTo,
            differenceInDays: differenceInDays,
            need_delivery : 0
          },
        })
      ) )
    }

  };


  const onChange = (checked) => {
    setStateMap(checked)
  };

  const handleLKeyUp = (e) => {
    var longlat = /\/\@(.*),(.*),/.exec(e.target.value);
    if(longlat){
      setLat(longlat[2])
      setLng(longlat[1])
    }else{
      return
    }
  };

  
  return (
    <>
      <Helmet>
        <title> أجرها | الرئيسية </title>
      </Helmet>
      <header className="main-header">
        <img src={logo} alt="logo" />
      </header>
      <div className="app-content bg-light">
        <h3 className="mb-4">استأجر سيارتك الأن</h3>
        
        <Form
          size="large"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h6 className="mb-2">استلام السيارة</h6>
          <Form.Item
            name="city_id"
            rules={[
              {
                required: true,
                message: "الحقل مطلوب",
              },
            ]}
          >
            {error ? (
              error
            ) : (
              <Select showSearch loading={loading} placeholder="المدينة" allowClear filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
                {dataCities?.data?.map((item, index) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {/* <Form.Item 
            name="number"
            rules={[
              {
                required: true,
                message: 'الحقل مطلوب',
              },
            ]} >
            <Select
              placeholder="الحي"
              allowClear >
              <Option value="male">male</Option>
              <Option value="female">female</Option>
              <Option value="other">other</Option>
            </Select>
          </Form.Item> */}

          <Row gutter={10}>
            <Col xs={12}>
              <Form.Item
                name="date_from"
                rules={[
                  {
                    required: true,
                    message: "الحقل مطلوب",
                  },
                ]}
              >
                <DatePicker inputReadOnly={true} locale={locale} disabledDate={(current) => {
                  return moment().add(-1, 'days')  >= current 
                  }}
                  onChange={onChangeDateFrom}
                  style={{ width: "100%" }}
                  placeholder="يوم/شهر/سنة"
                  />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="clock_from"
                rules={[
                  {
                    required: true,
                    message: "الحقل مطلوب",
                  },
                ]}
              >
                <TimePicker inputReadOnly={true} locale={locale} use12Hours format="h:mm a" 
                  onChange={onChangeClockFrom}
                  style={{ width: "100%" }}
                  placeholder="التوقيت"
                />
              </Form.Item>
            </Col>
          </Row>

          <h6 className="mb-2">تسليم السيارة</h6>

          <Row gutter={10}>
            <Col xs={12}>
              <Form.Item
                name="date_to"
                rules={[
                  {
                    required: true,
                    message: "الحقل مطلوب",
                  },
                ]}
              >
                <DatePicker inputReadOnly={true} locale={locale}  disabledDate={(current) => {
                  return moment().add(-1, 'days')  >= current 
                  }}
                  onChange={onChangeDateTo}
                  style={{ width: "100%" }}
                  placeholder="يوم/شهر/سنة"
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                name="clock_to"
                rules={[
                  {
                    required: true,
                    message: "الحقل مطلوب",
                  },
                ]}
              >
                <TimePicker inputReadOnly={true} use12Hours format="h:mm a" locale={locale}
                  onChange={onChangeClockTo}
                  style={{ width: "100%" }}
                  placeholder="التوقيت"
                />
              </Form.Item>
            </Col>
          </Row>
          {differenceInDaysError ? <h5 className="text-danger mb-2">لا يمكن اختيار سيارة الاستلام والتسليم بنفس الوقت </h5> :  null}
          {differenceInDaysError2 ? <h5 className="text-danger mb-2">لا يمكن اختيار سيارة يجب ان يكون تاريخ تسليم السيارة بعد استلام السيارة</h5> : null}
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="text-gray">توصيل السيارة لمكان محدد</h6>
            <Switch onChange={onChange} />
          </div>
          {!!stateMap && stateMap ? (
           <div className="mb-4">
             <Input placeholder="ادخل رابط الموقع" onKeyUp={(e) => handleLKeyUp(e)} />
           </div>
          ) : null}
          <Form.Item>
            <Button type="yellow" block htmlType="submit">
              بحث
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Home;
