import { PROFILE_ERROR, PROFILE_REQUEST, PROFILE_SUCCESS, UPDATEPROFILE_ERROR, UPDATEPROFILE_REQUEST, UPDATEPROFILE_SUCCESS } from "../../actions/profile/types";

const StateProfile = {
  dataFaq: null,
  loading: false,
  error: "",
};
// 


export const profile = function (state = StateProfile, action) {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        dataProfile:action.payload
      }; 
    case PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 
export const updateProfile = function (state = StateProfile, action) {
  switch (action.type) {
    case UPDATEPROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATEPROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        dataProfile:action.payload
      }; 
    case UPDATEPROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// 