

import { PROFILE_ERROR, PROFILE_REQUEST, PROFILE_SUCCESS, UPDATEPROFILE_ERROR, UPDATEPROFILE_REQUEST, UPDATEPROFILE_SUCCESS } from "./types.js";
import axiosApp from "../../../api/index.js";

export const getProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: PROFILE_REQUEST,
    });
    const response = await axiosApp.get(`/account/profile`);
    dispatch({
      type: PROFILE_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: error.response.data.message,
    });
  }
};


export const updateProfile = ({values ,callback}) => async (dispatch) => {
  console.log(values)
  try {
    dispatch({
      type: UPDATEPROFILE_REQUEST,
    });
    const response = await axiosApp.post(`/account/profile/update` , values);
    dispatch({
      type: UPDATEPROFILE_SUCCESS,
      payload: response.data.data,
    });
    callback()
  } catch (error) {
    dispatch({
      type: UPDATEPROFILE_ERROR,
      payload: error.response.data.message,
    });
  }
};



