import { Card, Col, Divider, Pagination, Radio, Row, Select, Spin } from "antd";
import React, { useEffect , useState } from "react";
import { Helmet } from "react-helmet";
import {  useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import EmptyData from "../../../components/EmptyData";
import { getCars, getSubcategories } from "../../../store/actions/car";
import { getCities } from "../../../store/actions/constants";
// import { getCars } from "../../../store/actions/car";

const CarList = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

 const [stateCities, setStateCities] = useState(state?.city_id)
 const [statePage, setStatePage] = useState()
 const [stateCategories, setStateCategories] = useState()

  const { Option } = Select;
  
  const { dataCars, loading, error } = useSelector((state) => state.cars);
  const { dataSubcategories, loading:loadingSubcategories, error:errorSubcategories } = useSelector((state) => state.subcategories);
  const { dataCities, loading:loadingCities, error:errorCities } = useSelector((state) => state.cities);
  // const params = new URLSearchParams(state.city_id)


  
  useEffect(() => {
    if(dataCars?.pagination?.total_pages === 1){
      setStatePage()
      dispatch(
        getCars({
          params: {
            city_id:stateCities,
            categories:[stateCategories]
          },
        })
      );
    }else{
      dispatch(
        getCars({
          params: {
            city_id:stateCities,
            page:statePage,
            categories:[stateCategories]
          },
        })
      );
    }
    
  }, [stateCities , statePage ,stateCategories , dataCars?.pagination?.total_pages]);

  useEffect(() => {
    dispatch(
      getCities()
    );
    dispatch(
      getSubcategories(81)
    );
  }, []);

    

  const onChangeFilter = (e) => {
    {e.target.value === 0  ? (
      setStateCategories()
    ) : (
      setStateCategories(e.target.value)
    )}
  };

  const onChangeCities = (value) => {
    console.log(value)
    setStateCities(value)
  };
 
  const ChangePagination = (number) => {
    setStatePage(number)
  };



  if(state === null){
    navigate('/')
  }

  return (
    <>
      <Helmet>
        <title>السيارات - Car </title>
      </Helmet>
      <div className="app-content pl-0 pr-0 pt-0">
        <div className="brand-filter mb-5">
          <Row className="mb-3">
            <Col span={24}>
              {errorCities ? errorCities : (
                <Select style={{width:"100%"}} defaultValue={state?.city_id} placeholder="المدينة"  loading={loadingCities} onChange={onChangeCities}>
                  {dataCities?.data?.map((item, index) => (
                    <Option key={index} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              ) }
            </Col>
          </Row>
          <Row>
          <Col span={24}>
          {loadingSubcategories ? (
            <Spin />
          ) : errorSubcategories ? (
            errorSubcategories
          ) : (
            <>
              {dataSubcategories?.data?.length ? (
                <div>
                  <h6 className="font-bold mb-3 mt-2">أشهر الماركات </h6>
                  <div className="scroll">
                  <Radio.Group onChange={onChangeFilter} className="filter-subcategories" defaultValue={0}>
                    <Radio.Button className="filter-subcategories-all" value={0}>الكل</Radio.Button>
                    {dataSubcategories?.data?.map((item, index) => (
                      <Radio.Button key={index} value={item.id}><img src={item.image} alt={item.name} /></Radio.Button>
                    ))}
                  </Radio.Group>
                  </div>
                </div>
              ) : (
                  null
              )}
            </>
          )}
           
          </Col>
        </Row>
        </div>
        {loading ? (
          <Row gutter={10}>
            <Col span={12}>
              <Card className="mb-3" loading={loading}></Card>
            </Col>
            <Col span={12}>
              <Card className="mb-3" loading={loading}></Card>
            </Col>
          </Row>
        ) : error ? (
          error
        ) : (
          <>
            {dataCars?.data?.length ? (
              <div className="px-3">
              <Row gutter={10}>
                {dataCars?.data?.map((item, index) => (
                  <Col span={12} key={index}>
                    <Card className="widget__item-car">
                      {/* <Link className="widget__item-gift" to="">
                        <img src="assets/images/svg/gift.svg" alt="" />
                      </Link> */}
                      <div className="widget__item-image">
                        <Link to={`/cars/${item.id}`} state={{
                            from: state?.from,
                            to: state?.to,
                            lat: state?.lat,
                            lng: state?.lng,
                            need_delivery: state?.need_delivery,
                            differenceInDays:state?.differenceInDays
                          }}>
                          <img src={item.image} alt="" />
                        </Link>
                      </div>
                      <div className="widget__item-content p-2">
                        <h6 className="font-bold">
                          <Link to={`/cars/${item.id}`} className="text-dark" state={{
                              from: state?.from,
                              to: state?.to,
                              lat: state?.lat,
                              lng: state?.lng,
                              need_delivery: state?.need_delivery,
                              differenceInDays:state?.differenceInDays
                            }}>
                            {item.title}
                          </Link>
                        </h6>
                        <h6 className="font-12 text-gray">2021</h6>
                        <div className="d-flex align-items-center">
                          <div className="col-auto">
                            <img
                              className="widget__item-imgCompany"
                              src={item.company.image}
                              alt=""
                            />
                          </div>
                          <h6 className="mr-2 font-10">{item.company.name}</h6>
                        </div>
                      </div>
                      <div className="widget__item-footer d-flex align-items-center justify-content-center p-1">
                        {item.discount ? <h6 className="font-medium ml-2 line-through">{item.discount}</h6> : null}
                        <h6 className="font-bold ml-2 mr-2">{item.cost}</h6>
                        <h6 className="font-bold font-10">ريال/اليوم</h6>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <div className="text-center">
                <Pagination onChange={(number)=> ChangePagination(number)} defaultCurrent={dataCars?.pagination?.current_page} total={dataCars?.pagination?.total}  />
              </div>
              </div>
            ) : (
                <EmptyData />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CarList;
