import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let auth = JSON.parse(localStorage.getItem("user"));
  return (
    // auth?.api_token ? <Outlet/> : <Navigate to="/login"/>

    !auth || !auth.api_token ? (
      <Navigate to="/login" />
    ) : auth.api_token && !auth?.account.is_verified_mobile ? (
      <Navigate to="/login" />
    ) : (
      <Outlet />
    )
  );
};

export default PrivateRoutes;
