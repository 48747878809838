
// login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_VERIFY = 'LOGIN_VERIFY'


// REGISTER_REQUEST
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'


// LOGOUT
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'



// verify
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_ERROR = 'VERIFY_ERROR'





// UPDATE PASS
export const UPDATEPASS_REQUEST = 'UPDATEPASS_REQUEST';
export const UPDATEPASS_SUCCESS = 'UPDATEPASS_SUCCESS'
export const UPDATEPASS_ERROR = 'UPDATEPASS_ERROR'









// ForgetPassword
export const FORGETPASSWORD_REQUEST = 'FORGETPASSWORD_REQUEST';
export const FORGETPASSWORD_SUCCESS = 'FORGETPASSWORD_SUCCESS'
export const FORGETPASSWORD_ERROR = 'FORGETPASSWORD_ERROR'


// ForgetPassword
export const RESETPASSWORD_REQUEST = 'RESETPASSWORD_REQUEST';
export const RESETPASSWORD_SUCCESS = 'RESETPASSWORD_SUCCESS'
export const RESETPASSWORD_ERROR = 'RESETPASSWORD_ERROR'


//  VERIFYPASSWORD
export const VERIFYPASSWORD_REQUEST = 'VERIFYPASSWORD_REQUEST';
export const VERIFYPASSWORD_SUCCESS = 'VERIFYPASSWORD_SUCCESS'
export const VERIFYPASSWORD_ERROR = 'VERIFYPASSWORD_ERROR'

//  CHANGEPASSWORD
export const CHANGEPASSWORD_REQUEST = 'CHANGEPASSWORD_REQUEST';
export const CHANGEPASSWORD_SUCCESS = 'CHANGEPASSWORD_SUCCESS';
export const CHANGEPASSWORD_ERROR = 'CHANGEPASSWORD_ERROR';



export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';