

import axiosApp from "../../../api/index.js";
import { ORDER_ERROR, ORDER_REQUEST, ORDER_SUCCESS } from "./types.js";

export const getOrder = ({params}) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_REQUEST,
    });
    const response = await axiosApp.get(`/orders/list` , {params});
    dispatch({
      type: ORDER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_ERROR,
      payload: error.response.data.message,
    });
  }
};
