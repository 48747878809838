

import axiosApp from "../../../api/index.js";
import { ABOUT_ERROR, ABOUT_REQUEST, ABOUT_SUCCESS, PRIVACY_ERROR, PRIVACY_REQUEST, PRIVACY_SUCCESS } from "./types.js";

export const getPrivacy = () => async (dispatch) => {
  try {
    dispatch({
      type: PRIVACY_REQUEST,
    });
    const response = await axiosApp.get(`/pages/privacy`);
    dispatch({
      type: PRIVACY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: PRIVACY_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const getAbout = () => async (dispatch) => {
  try {
    dispatch({
      type: ABOUT_REQUEST,
    });
    const response = await axiosApp.get(`/pages/about`);
    dispatch({
      type: ABOUT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ABOUT_ERROR,
      payload: error.response.data.message,
    });
  }
};
