import { Alert, Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EmptyData from "../../../components/EmptyData";
import { getTickets } from "../../../store/actions/tickets";
import chevron from "../../../assets/images/svg/chevron-right.svg";

const TicketsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dataTickets, loading, error } = useSelector((state) => state.tickets);

  useEffect(() => {
    dispatch(getTickets());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>التذاكر - Car </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">الدعم الفني</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? <Alert message={error} type="error" /> : (
          <>
            {dataTickets?.data?.length ? (
              <div className="mb-5">
                {dataTickets?.data.map((item, index) => (
                  <Link key={index} to={`/tickets/${item.id}`} className="widget__item-ticket">
                    <Row>
                      <Col span={6}>
                        <h6 className="font-12 font-medium">{item.id}</h6>
                      </Col>
                      <Col span={12}>
                        <h6 className="font-12 text-center">{item.title}</h6>
                      </Col>
                      <Col span={6}>
                        <h6 className={`font-10 font-medium text-center status-${item.status.name === "جديدة" ? "primary"  : null }`}>{item.status.name}</h6>
                      </Col>
                    </Row>
                  </Link>
                ))}
              </div>
            ) : (
              <EmptyData />
            )}
          </>
        )}
        <Link to="/tickets/add" className="ant-btn-yellow btn-block mt-5">أضف تذكرة جديدة</Link>
      </div>
    </>
  );
};

export default TicketsList;
