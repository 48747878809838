import { Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import EmptyData from "../../components/EmptyData";
import { getAbout } from "../../store/actions/pages";
import chevron from "../../assets/images/svg/chevron-right.svg";
import { useNavigate } from "react-router-dom";

const About = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { dataAbout, loading, error } = useSelector((state) => state.about);

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> أجرها | عن التطبيق </title>
      </Helmet>
      <header className="main-header">
        <Row className="w-100">
          <Col xs={8} onClick={() => navigate(-1)} ><img className="icon" src={chevron} alt="" /></Col>
          <Col xs={8} className="text-center">من نحن</Col>
        </Row>
      </header>
      <div className="app-content bg-light">
        {loading ? (
          <div className="text-center">
            <Spin />
          </div>
        ) : error ? error : (
          <>
            {!!dataAbout? (
              <div>{dataAbout.data.description}</div>
            ) : (
              <EmptyData />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default About;
